// External libraries
@import 'breakpoint';

// SASS vairables
$white: #fff;
$black: #444;
$gray:  #eaeaea;
$red:   #f00;
$blue:  #09c;

// Breakpoints
$tablet: 541px;
$desktop: 960px;
