@import 'partials/variables';

// General styling ---------------
body {
  font-family: 'Bitter', serif;

  &.front {
    [role='main'] {
      p {
        color: $blue;
      }
    }
  }
}

p {
  line-height: 1.5em;
}

h1,
h2,
h3,
h4 {
  color: $black;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.62em;
}

img {
  height: auto;
  max-width: 100%;
}

li {
  font-size: .9em;
  line-height: 1.8em;
}

ul,
ol {
  margin-bottom: .8em;
}

ul li {
  list-style-type: square;
  margin: 1em;
}

ol li {
  list-style-type: decimal;
  margin: 1em;
}

a {
  color: $blue;
}

table {
  margin-bottom: 1em;
  margin-top: .5em;
}

td {
  padding-bottom: .3em;
  padding-right: 2em;
}

// Header ---------------
hgroup {
  h1,
  h2 {
    color: $blue;
  }

  h1 {
    font-size: 2em;
    line-height: 1.5em;
  }
}

header {
  hgroup {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  nav {
    background-color: $blue;
    font-size: 1.2em;
    margin-top: 1em;
    padding: 10px;

    @include breakpoint($tablet) {
      margin-top: 0;
      padding: 10px 5px;
    }

    & > ul {
      @include breakpoint($desktop) {
        display: flex;
        justify-content: space-around;
        padding: 0;
      }
      margin: 0;
      padding: 0 1em;

      li {
        line-height: 1em;
        margin: 0;
        padding: .5em 0;

        @include breakpoint($tablet) {
          display: inline-block;
          width: 49%;
        }

        @include breakpoint($desktop) {
          display: initial;
          width: auto;
        }
      }
    }
  }
}

.hamburger,
.cross {
  background: none;
  border: 0;
  color: $blue;
  font-size: 1.5em;
  line-height: 42px; // same height as logo on mobile
}

nav {
  a,
  a.active-trail {
    color: $white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;

    &:hover {
      color: $gray;
      text-decoration: none;
    }
  }
}

// Sidebar ---------------
.page {
  .sidebar-first {
    nav {
      h2 {
        border-bottom: 2px solid $white;
        color: $white;
        font-size: 1.5em;
        font-weight: 400;
        padding-bottom: .4em;
      }

      li {
        line-height: 2em;
      }

      ul {
        li {
          list-style-position: outside;
          list-style-type: none;
          margin: .5em 0;

          a {
            font-size: 1em;
          }
        }
      }
    }
  }
}


// Breadcrumbs ---------------
.breadcrumb,
.breadcrumb a,
.breadcrumb a.active  {
  color: $blue;
  font: normal .9em 'Open Sans', sans-serif;
  text-decoration: none;
  text-transform:	lowercase;
}

// Main content ---------------
[role='main'] {
  h1 {
    color: $black;
    font-size: 1.62em;
    line-height: 1.62em;
    margin-bottom: 1.2em;
  }

  h2 {
    font-size: 1.2em;
    font-weight: 600;
    margin: 1.2em 0 1em;
  }

  h3 {
    margin-bottom: .5em;
    margin-top: 1em;
  }

  strong,
  b {
    font-weight: 700;
  }

  .views-field-title,
  .views-field-title a {
    color: $black;
    font-size: 1.1em;;
    font-weight: 300;
    margin-bottom: .2em;
  }

  p,
  .front_block p {
    font-size: .9em;
    margin-bottom: .7em;
  }

  footer {
    font-size: .9em;
    margin-bottom: 1.3em;

    .date {
      font-family: 'Open Sans', sans-serif;
      font-size: .8em;
      font-weight: 600;
    }
  }
}

.front_blocks {
  ul {
    margin-top: .3em;
  }

  .item-list ul li {
    list-style-type: square;
    margin-left: 1em;
  }

  ul {
    a {
      color: $blue;
    }
  }

  a {
    color: $blue;
    text-decoration: underline;
  }
  strong {
    color: $red;
  }
}

// Footer
[role='contentinfo'] {
  color: $black;

  strong,
  b {
    font-weight: 700;
  }

  p {
    font-size: .9em;
  }
}

// News detail page ---------------
.node-type-news {
  [role='main'] {
    h1 {
      margin-bottom: .3em;
    }
  }
}

// Contact form ---------------
input[type=text],
input[type=password],
input[type=email],
textarea {
  background-color:$gray;
  border: 1px solid $white;
  color: $black;
  font-family: 'Jura', serif;
  font-size: 1em;
  font-weight: 300;
  height: 40px;
  padding-left: 1%;
  width: 99%;
}

form {
  .form-required {
    margin-bottom: 30px;
  }
}

input:focus {
  border: 1px solid $black;
}

.form-textarea-wrapper {
  textarea {
    box-sizing: content-box;
    display: block;
    font-family: 'Jura', serif;
    font-style: normal;
    height: 10em;
    padding: 1%;
    width: 98%;
  }
}

.webform-submit {
  background: $blue;
  border: 0;
  color: $white;
  font: 1em 'Open Sans', sans-serif;
  font-weight: 300;
  height: 40px;
  margin-bottom: 1px;
  padding: 0 20px;

  &:active {
    margin-bottom: 0;
    margin-left: 3px;
    margin-top: 1px;
  }
}

form {
  label {
    color: $black;
    display: block;
    font-family: 'Jura', serif;
    font-weight: 300;
    margin-bottom: 10px;

    &.required {
      &::after {
        color: $red;
        content: '*';
      }
    }
  }
}

.resizable-textarea {
  .grippie {
    display: none;
  }
}

#edit-submitted-specialisme {
  div {
    padding-bottom: .5em;
  }

  label {
    display: inline;
  }
}

input[type='radio'] {
  appearance: none;
  background-color: transparent;
  border: 1px solid $black;
  border-radius: 50px;
  display: inline-block;
  padding: 6px;
  position: relative;
  top: -2px;

  &:checked {
    border: 1px solid $black;

    &::after {
      background: $blue;
      border-radius: 50px;
      content: ' ';
      font-size: 32px;
      height: 8px;
      left: 2px;
      position: absolute;
      text-shadow: none;
      top: 2px;
      width: 8px;
    }
  }
}

// Slider ---------------
.flexslider {
  li {
    margin: 0;
  }
}
